import ContentstackSDK from "@contentstack/app-sdk"
import ViewVideo from './pages/ViewVideo'
import CreateVideo from './pages/CreateVideo'
import { useEffect, useState } from "react";
import "./App.css";
import { EmptyState } from "@contentstack/venus-components";
import FileNotFoundIcon from './images/not-found.png';
import ErrorIcon from './images/error.png';
import { getFileKey } from '../utils/utill';
declare global {
  interface Window { 
    sdk:any;
    fullMetaData:any;
  }
}


function AssetSidebarWidget() {
  let [currentAssert,setCurrentAssert] = useState({} as any);
  let [metaData,setMetaData] = useState(null as any);
  let [isVideoStramable,setIsVideoStramable] = useState(false);

  useEffect(() => {
    init();
  },[])

  useEffect(() => {
    if(metaData && metaData.videoStatus === 'complete'){
      setIsVideoStramable(true);
    }
  },[metaData])

  const init = async ()=>{
    window.sdk = await ContentstackSDK.init();
    let currentAssert = window.sdk.location.AssetSidebarWidget?.currentAsset;
    let extentionUid = window.sdk.locationUID;
    window.fullMetaData = currentAssert?._metadata?.extensions[extentionUid]?.find((v:any) => v.file);
    if(window.fullMetaData){
      let metaDataValue = window.fullMetaData.file[getFileKey(currentAssert.url)];
      if(metaDataValue){
        setMetaData(metaDataValue);
      }
    }
    setCurrentAssert(window.sdk.location.AssetSidebarWidget?.currentAsset);
  }

  if(currentAssert.content_type !== "video/mp4"){
    return (<>
      <EmptyState
        type="primary"
        heading="File Type Not Supported"
        headingType="large"
        description="The selected video file type is not supported. Please use a video file of compatible format to avail the service."
        displayImage={true}
        imgSrc={{ src:FileNotFoundIcon, alt:"File not supported" }}
    />
    </>)
  }

  if(metaData?.videoStatus === "error"){
    return (<>
      <EmptyState
        type="primary"
        heading="Streaming not Supported"
        headingType="large"
        description="We encountered an error while converting your video to the streaming format."
        displayImage={true}
        imgSrc={{ src:ErrorIcon, alt:"Error" }}
    />
    </>)
  }


  return (
    <>
      <div id="wrapper">
        {isVideoStramable? 
            <ViewVideo metaData={metaData} currentAsset={currentAssert} setMetaData={setMetaData}/> : 
            <CreateVideo currentAsset={currentAssert} metaData={metaData} setMetaData={setMetaData} />  }
      </div>
    </>
  )
}

export default AssetSidebarWidget;
