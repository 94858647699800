import { AssetCardVertical, Button, ButtonGroup, Dropdown, Icon, InfiniteScrollTable, ModalBody, ModalFooter, ModalHeader, Search, Tooltip } from "@contentstack/venus-components";
import styles from './style.module.css';
import { cx, css } from '@emotion/css';
import { useEffect, useState } from "react";
import { getAllVideoApi, getUsers } from '../../utils/VodApiCall'
import { getAssetType } from "../../utils/utill";
import InfiniteScroll from 'react-infinite-scroll-component';
import Gridloader from './component/Gridloader';
import React from "react";

const emptyStyle = {
    color: '#4a5568',
    fontSize: '1.375rem',
    fontWeight: '800',
    letterSpacing: '-.00019em',
    lineHeight: '1.6875rem',
    marginTop: 0,
  }

const NoAssets = (props:any) => {
    return props.length === 0 ? (
      <div style={{ textAlign: 'center', height: '292px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <p style={emptyStyle}>No Records Found</p>
      </div>
    ) : props.children
  }

function SelectVideoModal(props:any) {
    const limit = 5;
    let [ users , setUsers ] = useState({} as any);
    let [ selectedVideo, setSelectedVideo ] = useState(null as any);
    let [ searchString, setSearchString ] = useState('');
    let [ loading, setLoading ] = useState(false);
    let [ forderUid, setForderUid ] = useState('cs_root');
    let [ index, setIndex ] = useState({});
    let [ skip, setSkip ] = useState(0);
    let [ listType , setListType] = useState('List');
    let [ itemStatusMap, setItemStatusMap ] = useState({} as any);
    let [ breadcrumbs, setBreadcrumbs ] = useState([{
        displayName: 'All Assets',
        action: () => {
            setBreadcrumbs(currBreadcrumbs => (currBreadcrumbs.slice(0, 1)) );
            setForderUid('cs_root');
        }
    } as any] as any[]);
    const [state, setState] = useState({
        assets: [],
        count : 0,
        startIndex:0,
        stopIndex:0
    } as any);

    useEffect(() => {
        init();
    },[])

     /* runs only once */
    useEffect(() => {
        fetchOnChange({ skip: 0, limit: 30, startIndex: 0, stopIndex: 30 });
    }, [searchString, forderUid]);

    const listColumns = [{
        Header: 'Name',
        id: 'title',
        accessor: (data:any) => {
            let icon = getAssetType(data.content_type);
            return (
            <div className='flex-v-center'>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <span className={cx(styles['list-icon'], css`
                        background: ${data.thumbnail ? `url(${data.thumbnail})` : 'inherit'};
                    `)}>
                        {(icon && !data.thumbnail ) && <Icon icon={icon} />}
                    </span>
                    {data.title}
                </div>
            </div>
            )
      },
      default: false,
      addToColumnSelector: true,
    },
    {
      Header: 'Last Modified By',
      id: 'updated_by',
      accessor: (data:any) => <div className={'last-updated-by'}><span>{data.modifiedBy}</span> <span className='last-updated-by-time'>{data.updatedAt}</span></div>,
      default: false,
      addToColumnSelector: true,
      disableSortBy: true,
    },
    {
      Header: 'Status',
      accessor: 'status',
      id: 'status',
      default: false,
      addToColumnSelector: true,
      disableSortBy: true,
    }];

    const dropdownList = [
        {
          label: <span className={styles['dropdown-item']}><Icon icon='List' className={styles['margin-right']} /> List</span>,
          action: () => {
            setListType('List')
          },
          default: listType === 'List',
        },
        {
          label: <span className={styles['dropdown-item']}><Icon icon='Thumbnail' className={styles['margin-right']} /> Thumbnail</span>,
          action: () => {
            setListType('Grid')
          },
          default: listType === 'Grid',
        },
      ]

    const handleClick = (asset:any) => {
        if (asset && asset.isDir) {
            setBreadcrumbs([...breadcrumbs,{
                displayName: asset.title,
                action: (len = breadcrumbs.length, uid = asset.uid) => {
                    setBreadcrumbs(prevState => {
                        return breadcrumbs.slice(0, len + 1)
                  });
                  setForderUid(uid);
                }
            }]);
            setForderUid(asset.uid);
            setSearchString('');
            return;
        }
        setSelectedVideo(asset);
    }

    const init = async ()=>{
        let usersRes:Map<string,string> = await getUsers({});
        setUsers(usersRes);
    }

    const addVideo = () => {
        console.log(selectedVideo, 'selectedVideo');
        if (selectedVideo) {
            props.addVideo(selectedVideo.video);
            props.closeModal();
        }
    }

    const getAssets = async (arg:any) => {
        console.log(arg, 'arg');
        const instanceItemStatusMapped:any = {};
        for (let i = arg.startIndex; i <= arg.stopIndex; i++) {
          instanceItemStatusMapped[i] = 'loading';
        }

        setItemStatusMap({...instanceItemStatusMapped});
        let assetQuery = window.sdk.stack.Asset.Query()
            .addParam('asset_type','videos')
            .addParam("include_count", "true")
            .addParam("include_folders","true")
            .addParam("folder", forderUid);

        if(arg.asc_field){
            assetQuery = assetQuery.addParam("asc", arg.asc_field);
        } 
        if(arg.dsc_field){
            assetQuery = assetQuery.addParam("dsc", arg.dsc_field);
        }   
        if(searchString){
            assetQuery = assetQuery.regex('filename',`^${searchString}`);
        }
        if(arg.skipSize){
            assetQuery = assetQuery.skip(arg.skipSize);
        }
        let assetsData = await assetQuery.limit(arg.limit).find();

        if(assetsData?.count === 0){
            return [];
        }
        setLoading(true);
        let videoData  = await getAllVideoApi(assetsData.assets.map((a:any) => a.uid));
        let data = assetsData.assets.map((a:any) => {
            if(a.is_dir){
                return {
                    isDir:true,
                    title:a.name,
                    uid:a.uid,
                    content_type:a.content_type
                }
            }
            let data = {
                status:"Not Converted",
                isDir:false,
                title:a.filename,
                modifiedBy : users[a.updated_by] || 'Anonymous',
                updatedAt:new Intl.DateTimeFormat('en-GB', { dateStyle: 'medium', timeStyle: 'short' }).format(new Date(a.updated_at)),
                thumbnail:null,
                uid:a.uid,
                content_type:a.content_type,
                video:null,
                _invalid:true
            }
            let video = videoData.find((v:any) => v.asset_id === a.uid);
            if(!video){
                return data;
            }
            data.status = video.status;
            if(video.status === "complete"){
                data.status = "Streamable";
                data._invalid = false;
            }
            if(video.status === "error"){
                data.status = "Error";
            }
            if(video.status === "submitted" || video?.status === "progressing"){
                data.status = "In Progress";
            }
            data.thumbnail = (video.thumbnails?.length > 0 )?video.thumbnails[0]:null;
            data.video = video;
            return data;
        });
        for (let i = arg.startIndex; i <= arg.stopIndex; i++) {
            instanceItemStatusMapped[i] = 'loaded'
        }
        setLoading(false);
        return { assets:data, count:assetsData.count,instanceItemStatusMapped:instanceItemStatusMapped};
    }

    const fetchOnChange = async (arg:any) => {
        let sort:any = {
            asc_field: '',
            dsc_field: ''
          };
      
          if (arg.sortBy) {
            sort[`${arg.sortBy['sortingDirection']}_field`] = arg.sortBy['id'];
          }
        let data:any = await getAssets({...arg, ...sort});
        setState({
            assets: data.assets,
            count:data.count,
            startIndex:arg.startIndex,
            stopIndex:arg.stopIndex
        });
        setItemStatusMap({...data.instanceItemStatusMapped});
    }

    const handleSearchChange = (searchInput:string)=>{
        setSearchString(searchInput);
    }

    const loadMore = async (arg:any) => {
        if(skip === arg.skip && limit === arg.limit ){ return; }
        let sort:any = {
            asc_field: '',
            dsc_field: ''
        };
      
        if (arg.sortBy) {
            sort[`${arg.sortBy['sortingDirection']}_field`] = arg.sortBy['id'];
        }
        arg.skip = arg.skip === 0 ? arg.skip : arg.skip + 1;
        let data:any = await getAssets({ limit, 'skipSize': arg.skip, ...sort, 'startIndex': arg.startIndex, 'stopIndex': arg.stopIndex });
        setSkip(arg.skip);
        setState( (prevState:any) => ({
            assets: [...prevState.assets, ...data.assets],
            count:data.count,
            startIndex:arg.startIndex,
            stopIndex:arg.stopIndex
        }));
        setItemStatusMap( (prevousStateMap:any) => ({...prevousStateMap , ...data.instanceItemStatusMapped}));
    }

    const loadMoreGrid = () => {
        const skip = state.stopIndex;
        const limit = 30;
        const startIndex = state.stopIndex + 1;
        const stopIndex = startIndex + limit;
        loadMore({ skip, startIndex, limit, stopIndex });
      }

    return (
        <>
           <div id="scrte-image-modal">
                <ModalHeader title={'Select Video'} closeModal={props.closeModal} />
                <ModalBody className="modalBodyCustomClass">
                    <div id="breadcrumbs">
                        <div className={styles['breadcrumb']}>
                            <Icon icon='Folder' className={styles['mr-5']} />
                            {breadcrumbs.map((item, idx) => (
                                <span key={idx} style={{ cursor: 'pointer' }} onClick={() => item.action(idx)}>
                                    {item.displayName} /&nbsp;
                                </span>
                            ))}
                        </div>
                    </div>
                    <div id="search-section">
                        <div className={styles['scrte-search']}>
                            <Search
                                width="full"
                                placeholder="Search Assets"
                                corners="regular"
                                debounceSearch={true}
                                onChange={handleSearchChange}
                            />
                            <p className={styles['asset-count']}> {state.count} items </p>
                            <div className="Dropdown-wrapper">
                                <Dropdown closeAfterSelect={true} list={dropdownList} type="click" highlightActive={true} withArrow={true} dropDownPosition="left" >
                                <Tooltip content={'Change view'} position="top">
                                    {/* <Icon icon="List" className="List__icon" />  */}
                                    {listType === 'List' ? <Icon icon="List" className="List__icon" /> : <Icon icon="Thumbnail" className="List__icon" />}
                                </Tooltip>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                    <NoAssets length={state.count}>
                        { listType === 'Grid' && <div id="grid">
                            <div id='scrollableDiv' data-testid="grid-view" style={{ height: 265, overflow: 'auto', fontFamily: 'Inter' }}>
                                <InfiniteScroll
                                    loader={<Gridloader />}
                                    dataLength={state.assets.length}
                                    hasMore={state.assets.length < state.count}
                                    next={loadMoreGrid}
                                    style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)' }}
                                    scrollableTarget='scrollableDiv'
                                >
                                    {loading && <Gridloader />}
                                    {
                                        state.assets.map((asset:any) =>
                                            <AssetImage checked={selectedVideo?.uid === asset.uid} key={asset.uid} asset={asset} handleClick={handleClick}></AssetImage>
                                        )
                                    }
                                </InfiniteScroll>
                            </div></div>
                        }
                        { listType === 'List' && <div id="list">
                                <div style={{ height: '290px', overflow: "hidden" }} data-testid="list-view" className='scrte-infinite-list'>
                                    <InfiniteScrollTable
                                        tableHeight={290}
                                        data={state.assets}
                                        columns={listColumns}
                                        uniqueKey={'uid'}
                                        fetchTableData={fetchOnChange}
                                        loadMoreItems={loadMore}
                                        rowDisableProp={{ key: '_invalid', value: true }}
                                        loading={loading}
                                        totalCounts={state.count}
                                        onRowClick={handleClick}
                                        singleSelectedRowId={selectedVideo?.uid}
                                        itemStatusMap={itemStatusMap}
                                        columnSelector={false}
                                        minBatchSizeToFetch={limit}
                                        name={{ singular: 'Asset', plural: 'Assets' }}
                                    />
                                </div>
                            </div>  }
                    </NoAssets>
                </ModalBody>
                <ModalFooter>
                    <ButtonGroup>
                    <Button buttonType="light" onClick={props.closeModal}>
                        Cancel
                    </Button>
                    <Button data-testid="add-selected" disabled={!selectedVideo} onClick={addVideo} icon="CheckedWhite">
                        Add Selected Video
                    </Button>
                    </ButtonGroup>
                </ModalFooter>
            </div>
        </>
    )
}


const AssetImage =  React.memo(
    (props:any) => {
      const { handleClick, asset, checked } = props
      const { thumbnail , title, name, file_name } = asset
      return (
        <AssetCardVertical
          onClick={() => handleClick(asset)}
          url={thumbnail}
          title={title ?? name ?? file_name}
          isCardDisabled={asset._invalid}
          type={asset.isDir ? 'folder' : 'image'}
          imageWidth={'100%'}
          checked={checked}
        />
      )
    },
    (prev, curr) => {
      return prev.checked === curr.checked
    }
  )

export default SelectVideoModal;