import postRobot from 'post-robot';

const Q = (body:any) => {
    //@ts-ignore
  return postRobot.sendToParent('stackQuery', body);
}

export const createVideoApi = (data = {}) => {
    if(process.env.REACT_APP_IS_LOCAL){
        return vodApiCall("/videos",data,"POST");
    }
    return vodApiQCall("createStreamableVideo",data,"");
}


export const getSuggestedVideoResolutionsApi = (data = {}) => {
    console.log(data);
    if(process.env.REACT_APP_IS_LOCAL){
        return vodApiCall("/videos/suggested-resolutions",data,"POST");
    }
    return vodApiQCall("getSuggestedVideoResolutions",data,"");
}


export const getVideoApi = (videoId:string) => {
    if(process.env.REACT_APP_IS_LOCAL){
        return vodApiCall("/videos/"+videoId,undefined,"GET");
    }
    return vodApiQCall("getStreamableVideoDetails",undefined,videoId);
}

export const getAllVideoApi = (asset_id:string[]) => {
    // if(process.env.REACT_APP_IS_LOCAL){
    //     return vodApiCall("/videos?isLatest=true&asset_ids="+asset_id.join(','),undefined,"GET");
    // }
    return vodApiQCall("getStreamableVideos",null,"",{"isLatest":true , "asset_ids":asset_id.join(',')});
}

export const uploadImage = (blob:Blob,videoId:string,index:number) => {
    return window.sdk?.stack.Asset.handleUpload([blob], "vodUploadThumbnail", {
        customUploadHandler: {
          videoId: videoId, 
          fileName: `${index+1}.jpg`, 
          "params": {
            "urlKey": "VOD_URL"
          },
          "headers" :getHeaders()
        }
      });
}

const getHeaders = () => {
    let orgId = window.sdk.stack._data.org_uid;
    let stackId = window.sdk.stack._data.api_key;;
    let headers:any = {
        'Content-Type':'application/json',
        'organization_uid':orgId,
        'stack_id':stackId
    }
    return headers
}


const vodApiQCall = async (action:string, payload:any, uid:string, params:any = null) => {
   let options:any = {
        action:action,
        headers:getHeaders(),
        params:{
            urlKey:"VOD_URL"
        }
    }
    if(payload){
        options.payload = payload;
    }
    if(params){
        options.params = {...options.params, ...params}
    }
    if(uid){
        options.uid = uid;
    }
    try{
        const response = await Q(options);
        if (response.data) {
            return response.data;
        }
    }catch(err:any){
        throw new Error(err.message || err);
    }    
}

const vodApiCall = async (uri = '', data = {}, type = "GET") => {
    console.log(document.domain);
    let options:any = {
        method: type,
        headers: getHeaders()
    }
    if(type !== "GET"){
        options.body = JSON.stringify(data);
    }
    let url = `${process.env.REACT_APP_VOD_API}${uri}`;
    const response = await fetch(url, options);
    if (response.ok) {
        return response.json();
    }
    throw new Error(response.statusText);
}

export const getCurrentUserId = async (props:any) => {
    const res = await Q({
      action: 'getUser'
    });
    const json = await res.data;
    return json?.user?.uid;
  }

export const getUsers = async (props:any): Promise<Map<string,string>> => {
    const res = await Q({
      action: 'getStacks',
      params: {
        include_collaborators: true
      }
    });
    const json = await res.data;
    let collaborators = json['stack']?.collaborators || [];
    let collaboratorsMap:any = {};
    let currentUid = await getCurrentUserId(props);
    collaborators.forEach((user:any) => {
        return collaboratorsMap[user.uid] = (user.uid === currentUid) ? 'Me': `${user.first_name} ${user.last_name}`
    });
    return collaboratorsMap;
  }