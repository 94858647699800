import ContentstackSDK from "@contentstack/app-sdk";
import { AssetCardVertical, Button } from "@contentstack/venus-components";
import "@contentstack/venus-components/build/main.css";
import { useEffect, useRef, useState } from "react";
import { cbModal } from '@contentstack/venus-components'
import SelectVideoModal from './modal/selectVideoModal'
import OpenVideoModal from './modal/openVideoModal'
import { getiFrameCode } from "../utils/utill";
import { getVideoApi } from "../utils/VodApiCall";
import postRobot from "post-robot";
import './index.css';
import playIcon from './images/play.png';

declare global {
    interface Window { 
      sdk:any;
      extension_uid:any;
      iframeRef:any;
      postRobot:any;
    }
  }

function CustomField() {
    const ref = useRef(null);
    let extension:any;
    let [ video, setVideo ] = useState(null as any);


    useEffect(() => {
        init();
    },[])

    const init = async ()=>{
        window.iframeRef = ref.current;
        window.postRobot = postRobot;
        window.sdk = await ContentstackSDK.init();
        extension = window.sdk.location.CustomField;
        extension.frame.updateHeight(450);
        const config = await window.sdk.getConfig()
        const {extention_uid} = config;
        window.extension_uid = extention_uid;
        let data = extension.field.getData();
        console.log("data",data);
        if(data){
            let video = await getVideoApi(data.videoId);
            setVideo(video);
        }
    }

    const addVideo = (video:any) => {
        setVideo(video);
        window.sdk.location.CustomField.field.setData({
            url:video.playback_ids[0].url,
            embad_code:getiFrameCode(video.id),
            thumbnail:video.thumbnails,
            videoId:video.id
        });
        extension.frame.updateHeight(450);
    }

    const openChooseFromAssetModal = () => {
        cbModal({
            component: (extraProps:any) => <SelectVideoModal {...extraProps} addVideo={addVideo}/>,
            modalProps: {
                size: 'max',
                customClass: 'reference__modal',
                shouldReturnFocusAfterClose: false
            }
        })
    }

    const openVideoModal = () => {
        console.log("video",video);
        cbModal({
            component: (extraProps:any) => <OpenVideoModal {...extraProps} videoId={video.id}/>,
            modalProps: {
                size: 'max',
                customClass: 'reference__modal',
                shouldReturnFocusAfterClose: false
            }
        })
    }

    const removeVideo = () => {
        setVideo(null);
        window.sdk.location.CustomField.field.setData(null);
    }

    return (
        <>
        <div ref={ref} id="vod-custom-field">
            { !video && 
                <Button buttonType="tertiary-outline" onClick={() => openChooseFromAssetModal()}>
                    Choose a file
                </Button>
            }
            { video && 
                <div id="view-video">
                    <AssetCardVertical 
                        assetUrl={video.thumbnails[0]} 
                        title={video.title} 
                        assetType={"image"}
                        onEditClick={() => openChooseFromAssetModal()}
                        onCardClick={() => openVideoModal()}
                        onDeleteClick={removeVideo}
                    />
                    <img src={playIcon} className="play-icon" alt="" /> 
                </div>
            }
        </div>
        </>
    )
}

export default CustomField;
