import { useEffect, useRef } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  HashRouter
} from "react-router-dom";
import AssetSidebarWidget from "./asset-sidebar/index";
import CustomField from "./custom-field/index";
import DashboardWidget from "./dashboard-widget";

function App() {

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/asset-sidebar" element={<AssetSidebarWidget />} />
          <Route path="/custom-field" element={<CustomField />} />
          <Route path="/dashboard" element={<DashboardWidget />} />
        </Routes>
      </BrowserRouter>
    </>)
}

export default App;
