import { Button } from '@contentstack/venus-components';
import React, { useState } from 'react';
import ReactHlsPlayer from 'react-hls-player';

const SetThumbnail = (props:any) => {
    const playerRef:React.RefObject<HTMLVideoElement> | any = React.useRef();
    let [isLoading,setisLoading] = useState(false);

    const getVideoTime = async () => {
      console.log(props);
      let currentTime = playerRef.current.currentTime;
      console.log(currentTime);
      setisLoading(true);
      await props.setThumbnailTime(props.thumbnailIndex,currentTime);
      setisLoading(false);
      props.closeModal();
    }

    return <div
    style={{
      width: 'calc(100vw - 10px)',
      borderRadius: 'inherit',
    }}
  >
    <div>
        <ReactHlsPlayer
          src={props.videoUrl}
          autoPlay={false}
          controls={true}
          width="90%"
          height="auto"
          playerRef={playerRef}
          style={{
              margin:'20px'
          }}
        />
        <Button buttonType="secondary" onClick={getVideoTime} className="modal-buttons" loading={isLoading} >Set as Thumbnail</Button>

    </div>
  </div>
}

export default SetThumbnail