import { AsyncSelect, Button, FieldLabel, Notification, Paragraph, ProgressBar, Info, Icon } from '@contentstack/venus-components'
import { useEffect, useState } from 'react'
import { createVideoApi, getVideoApi, getSuggestedVideoResolutionsApi } from '../../utils/VodApiCall';
import { getFileKey, saveMetadata } from '../../utils/utill';
import "./createvideo.css"
import CSLogoPreloader from '../images/CSLogoPreloader.gif'

const CreateVideo = (props) => {
  const { currentAsset,metaData, setMetaData } = props

  let [isLoading,setisLoading] = useState(false);

  const [resolutionValue, updateResolutionValue] = useState(null)

  let [videoPollinginteval,setVideoPollingInteval] = useState(null);
  

  useEffect(() => {
    if(!props.metaData){
      return ;
    }
    if(["error", "complete", "canceled"].includes(props.metaData.videoStatus)){
      if(videoPollinginteval){
        clearInterval(videoPollinginteval);
        console.log("Stoping from useEffect");
      }
    }else{
      if(!videoPollinginteval){
        statVideoPolling();
      }
    }
  },[props.metaData])

  useEffect( () => () => {
    if(videoPollinginteval){
      clearInterval(videoPollinginteval);
      console.log("Stoping init from useEffect");
    }
  }, [] );

  const getVideoStatus = async () => {
    let data = await getVideoApi(metaData.videoId);
    if(data.status === metaData.videoStatus){
      return
    }
    let assetMetadata = {
      videoId:metaData.videoId,
      videoStatus:data.status
    };
    if(data.status === "complete"){
      if(data.playback_ids?.length){
        assetMetadata.videoUrl = data.playback_ids[0].url
      }
      assetMetadata.thumbnails = data.thumbnails
    }
    let updatedMetadata = await saveMetadata(assetMetadata,currentAsset);
    setMetaData(updatedMetadata);
  }

  const statVideoPolling = () => {
    getVideoStatus();
    if(!videoPollinginteval){
      setVideoPollingInteval(setInterval(getVideoStatus,1000*30));
    }
  }


  const createVideo = async () => {
    setisLoading(true);
    try{
      let createVideoData = {
        "asset_id":currentAsset.uid,
        "title":currentAsset.title,
        "version":currentAsset._version,
        "file_key":getFileKey(currentAsset.url),
        "filename":currentAsset.filename
      };
      if(resolutionValue){
        createVideoData.resolutions = resolutionValue.map(v => v.value);
      }
      let response = await createVideoApi(createVideoData);
      let updatedMetadata = await saveMetadata({videoStatus:response.status,videoId:response.id},currentAsset);
      setMetaData(updatedMetadata);
      Notification({ displayContent: { text: "Video creation Job started" },type:"success"});
    }catch(err){
      console.log(err);
      Notification({ displayContent: { text: "Something went wrong" },type:"error"});
    }finally{
      setisLoading(false);
    }
  }

  const loadMoreOptions = async () => {
    let suggestionData = {
      "asset_id":currentAsset.uid,
      "title":currentAsset.title,
      "version":currentAsset._version,
      "file_key":getFileKey(currentAsset.url),
      "filename":currentAsset.filename
    };
    const response = await getSuggestedVideoResolutionsApi(suggestionData);
    let options = [{label:"auto",value:"auto"}];
    response?.suggestedResolutions?.forEach(v => {
      options.push({label:v,value:v});
    })
    return { options: options, hasMore:false}
  }

  const handleValueUpdate = data => {
    let autoIndex = data.map(e =>  e.value ).indexOf('auto');
    let optionData = [];
    if(~autoIndex && data.length - 1 === autoIndex){
      optionData = [{label:"auto",value:"auto"}];
    }else if(~autoIndex){
      data.splice(autoIndex, 1);
      optionData = data;
    }else{
      optionData = data;
    }
    updateResolutionValue(optionData)
  }

  return (
    <> 
      <div id="assert-data-wrapper">
        <div className="assert-data">
          <FieldLabel htmlFor="label">Title</FieldLabel>
          <p className="text-value">{currentAsset.title}</p>
        </div>
        <div className="assert-data">
          <FieldLabel htmlFor="label">File Name</FieldLabel>
          <p className="text-value">{currentAsset.filename}</p>
        </div>
        <div className="assert-data">
          <FieldLabel htmlFor="label">Unique ID</FieldLabel>
          <p className="text-value">{currentAsset.uid}</p>
        </div>
        <div className="assert-data">
          <FieldLabel htmlFor="label">File URL</FieldLabel>
          <p className="text-value">{currentAsset.url}</p>
        </div>
      </div>
      <div id='create-video-wrapper'>
        {
          !metaData && <>   
            <Paragraph tagName='p' text="You can convert the video asset to a streamable format. You can optionally change the settings before the conversion." />   
            <div class="resolution-select">
              <AsyncSelect
                selectLabel="Select Required Video Resolution"
                value={resolutionValue}
                onChange={handleValueUpdate}
                placeholder="Select Resolution"
                loadMoreOptions={loadMoreOptions}
                isSearchable={false}
                width="100%"
                isMulti={true}
                isClearable
                defaultOptions={false}
                debounceTimeout={0}
              />
          </div>

            <Button buttonType="primary" onClick={createVideo} loading={isLoading}> Create Streaming Video</Button>
          </>
        }
        {
          metaData && <>
             <div className='CSLogoPreloaderContainer'>
                <img className='CSLogoPreloaderGif' src={CSLogoPreloader} alt="CS loading" />
              </div>
            <h6>Processing...</h6>
            <Paragraph tagName='p' text="We have sent your video file for transcoding. We will notify you on your email ID once it is ready." /> 
          </>
        }
      </div>
    </>
  )
}

export default CreateVideo