

export const getFileKey = (fileUrl:string) => {
    let urlArr = fileUrl.split("/");
    return urlArr.slice(urlArr.length-2,urlArr.length-1).join();
}


export const getiFrameCode = (videoId:string, thumbnailsUrl:any = null) => {
    let iframeUrlR;
    if(thumbnailsUrl?.length){
      let urlObj = new URL(thumbnailsUrl[0]);
      iframeUrlR = urlObj.searchParams.get("t");
    }else{
        iframeUrlR = `${Math.floor(Math.random() * 999)}`;
    }
    return `<iframe width="560" height="315" src="${process.env.REACT_APP_IFRAME_URL}?vid=${videoId}&poster=1&r=${iframeUrlR}" title="Contentstack video player" frameborder="0" allow="autoplay; encrypted-media; picture-in-picture" allowfullscreen></iframe>`;
}


export const saveMetadata = async (assetMetadata:any,currentAsset:any) => {
    if(!window.sdk){
        return
    }
    let {sdk, fullMetaData} = window;
    let data:any = {
        entity_uid: currentAsset.uid,
        type: "asset",
        extension_uid:sdk.locationUID,
        file:(fullMetaData)?window.fullMetaData.file:{}
    }
    data.file[getFileKey(currentAsset.url)] = assetMetadata;
    let res;
    if(!fullMetaData){
        res = await sdk.metadata.createMetaData(data);
    }else{
        data.uid = window.fullMetaData.uid
        res = await sdk.metadata.updateMetaData(data);
    }
    await window.sdk.location.AssetSidebarWidget?.syncAsset();
    window.fullMetaData = res?.data?.metadata;
    return window.fullMetaData.file[getFileKey(currentAsset.url)];
}

export const getAssetType = (content_type:string) => {
    let assetType:string = 'Document'
    if (content_type) {
      switch (true) {
        case content_type.indexOf('folder') > -1:
          assetType = 'Group'
          break
        case content_type.indexOf('pdf') > -1:
          assetType = 'Pdf'
          break
        case content_type.indexOf('excel') > -1:
          assetType = 'Document'
          break
        case content_type.indexOf('presentation') > -1:
          assetType = 'Document'
          break
        case content_type.indexOf('json') > -1:
          assetType = 'Document'
          break
        case content_type.indexOf('document') > -1:
          assetType = 'Document'
          break
        case content_type.indexOf('text/plain') > -1:
          assetType = 'Document'
          break
        case content_type.indexOf('text/html') > -1:
          assetType = 'Document'
          break
        case content_type.indexOf('zip') > -1:
          assetType = 'Archive'
          break
        case content_type.indexOf('video') > -1:
          assetType = 'Video'
          break
        case content_type.indexOf('audio') > -1:
          assetType = 'Audio'
          break
      }
      if (content_type.indexOf('image/tiff') > -1) {
        assetType = 'Document'
      }
  
      return assetType
    }
  
    return assetType
  }

