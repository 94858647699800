import { Button, ButtonGroup, ModalBody, ModalFooter, ModalHeader } from "@contentstack/venus-components";

function openVideoModal(props:any){
    return <>
        <ModalHeader title={'Video'} closeModal={props.closeModal} />
        <ModalBody className="modalBodyCustomClass">
            <iframe width="100%" style={{height:"80vh"}} src={`${process.env.REACT_APP_IFRAME_URL}?vid=${props.videoId}&poster=1`} title="Contentstack video player"  allow="autoplay; encrypted-media; picture-in-picture" ></iframe>
        </ModalBody>
    </>
}

export default openVideoModal;