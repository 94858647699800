import {  useEffect, useState } from 'react'
import ReactHlsPlayer from 'react-hls-player';
import { AssetCardVertical, cbModal, ClipBoard, FieldLabel } from '@contentstack/venus-components'
import { getVideoApi, uploadImage } from '../../utils/VodApiCall';
import { getiFrameCode, saveMetadata } from '../../utils/utill';
import "./viewvideo.css"
import React from 'react';
import SetThumbnail from './modal/SetThumbnail';

const ViewVideo = (props:any) => {
  let { metaData, currentAsset, setMetaData } = props;
  let [videoData, setVideoData ] = useState({} as any);
  const playerRef:React.RefObject<HTMLVideoElement> | any = React.useRef();
  
  useEffect(() => {
    init();
  },[])
  
  const init = async () => {
    let data = await getVideoApi(metaData.videoId);
    let reolutions = data?.outputDetails?.map((d:any) => `${d.height}p`);
    let createAt = new Intl.DateTimeFormat('en-GB', { dateStyle: 'medium', timeStyle: 'short' }).format(new Date(data.created_at)) ;
    let trackInfo = data?.tracks.find((v:any) => v['@type'] === "General");
    let videoInfo = data?.tracks.find((v:any) => v['@type'] === "Video");
    let framRate = Number(trackInfo?.FrameRate).toFixed(2);
    let duration = getTimeFromSecond(trackInfo?.Duration);
    let r = gcd(videoInfo?.Width, videoInfo?.Height);
    let aspectRatio = `${videoInfo?.Width/r} : ${videoInfo?.Height/r}`;
    let iFrameCode = getiFrameCode(metaData.videoId,metaData?.thumbnails);
    setVideoData({reolutions, createAt  , framRate , duration, aspectRatio, iFrameCode});
  }

  const truncate = function (fullStr:string, strLen:number) {
    if (fullStr.length <= strLen) return fullStr;
    
    let separator = '...';
    
    var sepLen = separator.length,
        charsToShow = strLen - sepLen,
        frontChars = Math.ceil(charsToShow/2),
        backChars = Math.floor(charsToShow/2);
    
    return fullStr.substr(0, frontChars) + 
           separator + 
           fullStr.substr(fullStr.length - backChars);
};


  const gcd = (a:number, b:number) : number => {
    return (b === 0) ? a : gcd (b, a%b);
  }

  const getTimeFromSecond = (seconds:number) => {
    return new Date(seconds * 1000).toISOString().substr(14, 5);
  }
  const getTimeDuration = (url:string) => {
    let urlObj = new URL(url);
    return (urlObj.searchParams.get("t"))?getTimeFromSecond(Number(urlObj.searchParams.get("t"))):"";
  }

  const dataURItoBlob = (dataURI:string):Blob=>{
    var byteStr;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteStr = atob(dataURI.split(',')[1]);
    else
        byteStr = unescape(dataURI.split(',')[1]);

    var mimeStr = dataURI.split(',')[0].split(':')[1].split(';')[0];

    var arr= new Uint8Array(byteStr.length);
    for (var i = 0; i < byteStr.length; i++) {
        arr[i] = byteStr.charCodeAt(i);
    }

    return new Blob([arr], {type:mimeStr});
  }

  const generateImage = (second:string,currentUrl:string[]):Promise<Blob>=>{
    return new Promise((resolve,reject) => {
      var canvas = document.createElement('canvas');
      var ctx = canvas.getContext('2d');
      var img1 = new Image();
      img1.onload = function () {
        // @ts-ignore
        let width = this.width
        canvas.width = width;
        // @ts-ignore
        let height = this.height/2;
        canvas.height = height;
        let top = 0;
        if(Number(second) % 2){
          top = height
        }
        // @ts-ignore
        ctx.drawImage(img1, 0, top,width,height,0,0,width,height);
        resolve(dataURItoBlob(canvas.toDataURL('image/jpeg')));
      };
      img1.crossOrigin = 'Anonymous';
      img1.src = currentUrl.slice(0,currentUrl.length - 2).join("/")+"/"+getThumbnailFileName(Number(second).toFixed(0))
    });
  }

  const setThumbnailTime = async (index:number, seconds:number) => {
    let metaDataCopy =  JSON.parse(JSON.stringify(metaData));
    let currentThumbnailArr = metaDataCopy.thumbnails[index].split('/');
    let buff:Blob = await generateImage(seconds.toFixed(0),currentThumbnailArr);
    await uploadImage(buff,metaData.videoId,index);
    let newThumbnaiFileName = `${currentThumbnailArr.slice(0,currentThumbnailArr.length - 2).join("/")}/thumbnail/${index+1}.jpg?t=${seconds.toFixed(0)}`;
    metaDataCopy.thumbnails[index] = newThumbnaiFileName;
    let res = await saveMetadata(metaDataCopy,currentAsset);
    return setMetaData(res);
  } 


  const getThumbnailFileName = (second:string) => {
    let thumbnailSecond = (Number(second)/2).toFixed(0);
    return "Thumbnail_"+'000000000'.substring(thumbnailSecond.length) + thumbnailSecond+".jpg";
  }
  const openSetThumbnail = (index:any) => {
    console.log(index);
    cbModal({
      component: (props:any) => <SetThumbnail {...props} videoUrl={metaData.videoUrl} thumbnailIndex={index} setThumbnailTime={setThumbnailTime} />,
      modalProps: {
        size: 'customSize',
        shouldCloseOnOverlayClick:true
      },
    })
  }

  if(!metaData){
    return (<><p>NO data </p> </>)
  }
  return (
      <>
        <ReactHlsPlayer
          src={metaData.videoUrl}
          autoPlay={false}
          controls={true}
          width="100%"
          height="auto"
          playerRef={playerRef}
        />
        <div id="asset-info-wrapper">
          <div className='video-url'>
            <FieldLabel htmlFor="label">Video URL</FieldLabel>
            <p className="text-value">{truncate(metaData.videoUrl || "",58)}</p>
            <ClipBoard copyText={metaData.videoUrl}>
              <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.75 3.5H0.75C0.3 3.5 0 3.8 0 4.25V11.75C0 12.2 0.3 12.5 0.75 12.5H6.75C7.2 12.5 7.5 12.2 7.5 11.75V4.25C7.5 3.8 7.2 3.5 6.75 3.5Z" fill="#647696"/>
                <path d="M9.75 0.5H2.25V2H9V10.25H10.5V1.25C10.5 0.8 10.2 0.5 9.75 0.5Z" fill="#647696"/>
              </svg>
            </ClipBoard>
          </div>
          <div className='video-embed'>
            <FieldLabel htmlFor="label">Embed Video</FieldLabel>
            <p className="text-value">{truncate(videoData.iFrameCode || "",58)}</p>
            <ClipBoard copyText={videoData.iFrameCode}>
              <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.75 3.5H0.75C0.3 3.5 0 3.8 0 4.25V11.75C0 12.2 0.3 12.5 0.75 12.5H6.75C7.2 12.5 7.5 12.2 7.5 11.75V4.25C7.5 3.8 7.2 3.5 6.75 3.5Z" fill="#647696"/>
                <path d="M9.75 0.5H2.25V2H9V10.25H10.5V1.25C10.5 0.8 10.2 0.5 9.75 0.5Z" fill="#647696"/>
              </svg>
            </ClipBoard>
          </div>
          <div >
            <FieldLabel htmlFor="label">Video ID</FieldLabel>
            <p className="text-value">{metaData.videoId}</p>
          </div>
          <div >
            <FieldLabel htmlFor="label">Available Resolutions</FieldLabel>
             <p className="text-value resolution-grid">{videoData.reolutions?.map((r:string) => <span className='resolutions-value'>{r}</span>) || ""}</p> 
          </div>
          <div >
            <FieldLabel htmlFor="label">Created At</FieldLabel>
            <p className="text-value">{videoData.createAt || ""}</p>
          </div>
          <div >
            <FieldLabel htmlFor="label">Duration</FieldLabel>
            <p className="text-value">{videoData.duration || ""}</p>
          </div>
          <div >
            <FieldLabel htmlFor="label">Max Frame Rate</FieldLabel>
            <p className="text-value">{videoData.framRate || ""}</p>
          </div>
          <div >
            <FieldLabel htmlFor="label">Aspect Ratio</FieldLabel>
            <p className="text-value">{videoData.aspectRatio || ""}</p>
          </div>
        </div>

        <FieldLabel htmlFor="label" className="thumbnail-lable">Thumbnails</FieldLabel>
        <div className="thumbnail-wrapper app">
            <ul className="thumbnail-list full">
              {metaData.thumbnails.map( (v:string,i:number) => {
                return  <AssetCardVertical
                title={getTimeDuration(v)}
                onEditClick={openSetThumbnail}
                cardData={i}
                onCardClick={console.log}
                assetType="image"
                assetUrl={v}
                key={i.toString()}
              /> 
                // return <li className="item"><img src={v} width="210" alt="thumbnail" /></li>
              })}
            </ul>
        </div>
      </>
    )
}

export default ViewVideo